<template>
  <v-app id="open-agf-app">
    <alert-bar/>
    <router-view/>
  </v-app>
</template>

<script>
import AlertBar from '@/components/AlertBar'

export default {
  name: 'Public',
  components: {
    AlertBar
  }
}
</script>
