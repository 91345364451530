import { appHeaders } from '@/api/utils'
import { handleApiErrors } from '@/api/apiErrors'

const ProfileApi = {
  me () {
    return fetch('/api/accounts/me', {
      headers: appHeaders(),
      method: 'POST',
    }).then(handleApiErrors)
      .then(response => response.json())
  },

  update (userData) {
    return fetch('/api/accounts/update/me', {
      headers: appHeaders(),
      body: JSON.stringify(userData),
      method: 'PATCH',
    }).then(handleApiErrors)
      .then(response => response.json())
  },

  updatePassword (passwordRequest) {
    return fetch('/api/accounts/update/password', {
      headers: appHeaders(),
      body: JSON.stringify(passwordRequest),
      method: 'PATCH',
    }).then(handleApiErrors)
      .then(response => response.json())
  },
}

export default ProfileApi
