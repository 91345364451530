import { DateTime } from 'luxon'

export default function install (Vue) {
  Object.defineProperties(Vue.prototype, {
    $dateTime: {
      get () {
        return DateTime
      },
    },
  })
}
