import { appHeaders } from '@/api/utils'
import { handleApiErrors } from '@/api/apiErrors'

const DevicesApi = {

  getBydId (deviceId) {
    return fetch(`/api/device/${deviceId}`, {
      headers: appHeaders(),
    }).then(handleApiErrors)
      .then((response) => response.json())
  },

  update (device) {
    return fetch(`/api/devices/update/${device.id}`, {
      headers: appHeaders(),
      method: 'PATCH',
      body: JSON.stringify(device)
    }).then(handleApiErrors)
      .then((response) => response.json())
  },

  delete (deviceId) {
    return fetch(`/api/devices/delete/${deviceId}`, {
      headers: appHeaders(),
      method: 'DELETE',
    }).then(handleApiErrors)
      .then((response) => response.json())
  },

  markActive(deviceId) {
    return fetch(
      `/api/devices/mark-active/${deviceId}`, {
        headers: appHeaders(),
        method: 'PATCH',
      }).then(handleApiErrors)
      .then((resp) => resp.json())
  },

  /**
   * Load all devices
   * @return {Promise<any|void>}
   */
  all () {
    return fetch('/api/devices/all', {
      headers: appHeaders(),
    }).then(handleApiErrors)
      .then((response) => response.json())
  },

  dataForDeviceId (deviceId, day) {
    return fetch(
      `/api/messages/device/${deviceId}/day/${day}`, {
        headers: appHeaders(),
      }).then(handleApiErrors)
      .then((resp) => resp.json())
  },

  lastUplink (deviceId) {
    return fetch(
      `/api/messages/device/${deviceId}/last`, {
        headers: appHeaders(),
      }).then(handleApiErrors)
      .then((resp) => resp.json())
  }
}

export default DevicesApi
