<template>
  <v-alert
      :type="messageType()"
      :value="displayAlert"
      class="ma-0"
      dismissible
      transition="fade-transition"
  >
    {{ messageText() }}
  </v-alert>
</template>
<script>
export default {
  computed: {
    displayAlert () {
      return Object.keys(this.$store.getters['globalStore/flash']).length > 0
    },
  },
  methods: {
    messageType () {
      return this.$store.getters['globalStore/flash'].type
    },
    messageText () {
      return this.$store.getters['globalStore/flash'].message
    },
  },
}
</script>

