import Vue from 'vue'
import Router from 'vue-router'
import store from '@/stores/index'
import DashboardLayout from '@/layouts/DashboardLayout'
import PublicLayout from '@/layouts/PublicLayout'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: PublicLayout,
      children: [{
        path: '/',
        name: 'login',
        component: () => import('@/views/Login.vue'),
      }]
    },
    {
      path: '/register',
      children: [{
        path: '/',
        name: 'register',
        component: () => import('@/views/Register.vue'),
      }]

    },
    {
      path: '/',
      component: DashboardLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: '/',
          name: 'dashboard',
          props: true,
          component: () => import('@/views/Dashboard.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/devices',
          name: 'devices',
          props: true,
          component: () => import('@/views/Devices.vue'),
          meta: { requiresAuth: true },
        },
        {
          path: '/devices/:deviceId/edit',
          name: 'device-details',
          component: () => import('@/views/DeviceDetails.vue'),
          props: true,
          meta: { requiresAuth: true },
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import('@/views/Profile.vue'),
          meta: { requiresAuth: true },
        },
      ]
    },
    // keep this as last route to catch not existing routes
    {
      path: '*',
      component: () => import('@/views/NotFound.vue'),
    },
  ],
})

/**
 * Check if we have a user in context for protected routes.
 * See https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards
 */
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    const authenticated = store.getters['globalStore/isAuthenticated']
    if (!authenticated) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
