export const RESET_STATE = 'RESET_STATE'
export const RESET_FLASH = 'RESET_FLASH'
export const ADD_FLASH_MESSAGE = 'ADD_FLASH_MESSAGE'

export const SET_TOKEN = 'SET_TOKEN'
export const SET_AUTH_USER = 'SET_AUTH_USER'

// Device mutations
export const SET_DEVICE = 'SET_DEVICE'

// Local storage key's
export const LOCAL_STORAGE_AUTH_USER = 'open-agf.auth.user'
export const LOCAL_STORAGE_JWT_TOKEN = 'open-agf.jwt.token'
