/**
 * @param {string=} id
 * @param {string} type
 * @param {string} message
 * @returns {{id: string, type: string, message: string}}
 */
export const createFlashMessage = ({
  id = Math.random().toString(36).substr(2, 5),
  type = 'Anonymous',
  message = 'anon.png',
} = {}) => ({
  id,
  type, message,
})
