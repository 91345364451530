import {
  ADD_FLASH_MESSAGE,
  LOCAL_STORAGE_AUTH_USER, LOCAL_STORAGE_JWT_TOKEN,
  RESET_FLASH,
  RESET_STATE,
  SET_AUTH_USER,
  SET_TOKEN
} from '@/stores/mutations'
import { createFlashMessage } from '@/stores/utils'
import ProfileApi from '@/api/ProfileApi'

const initialState = () => {
  return {
    flash: {},
    token: JSON.parse(localStorage.getItem(LOCAL_STORAGE_JWT_TOKEN) || 'null'),
    user: JSON.parse(localStorage.getItem(LOCAL_STORAGE_AUTH_USER) || 'null'),
  }
}

const state = initialState

const getters = {
  flash (state) {
    return state.flash
  },
  isAuthenticated (state) {
    return (state.user && state.user.name) || false
  },
  user (state) {
    return state.user
  },
}

const actions = {
  addFlashMessage ({ commit }, message) {
    commit(ADD_FLASH_MESSAGE, message)
    setTimeout(() => {
      commit(RESET_FLASH)
    }, 6000)
  },
  setToken ({ commit, dispatch }, token) {
    return new Promise((resolve, reject) => {
      if (!token) {
        console.error('Invalid token')
        return reject(false)
      }
      commit(SET_TOKEN, token)
      return resolve(true)
    }).catch(() => dispatch('globalStore/reset', createFlashMessage({
      type: 'error',
      message: 'Authenticatie mislukt, probeer opnieuw'
    }), { root: true }))
  },
  setUser ({ commit, dispatch }, user) {
    return new Promise((resolve) => {
      commit(SET_AUTH_USER, user)
      resolve(true)
    }).catch(() => dispatch('globalStore/reset', createFlashMessage({
      type: 'error',
      message: 'Authenticatie mislukt, probeer opnieuw'
    }), { root: true }))
  },
  updateProfile ({ commit, dispatch }, updatedUserProfile) {
    return ProfileApi.update(updatedUserProfile).then(user => {
      commit(SET_AUTH_USER, user)
      return Promise.resolve(user)
    }).catch(reason => dispatch('globalStore/addFlashMessage', createFlashMessage({
      type: 'error',
      message: reason
    })))
  },
  updatePassword ({ dispatch }, passwordChange) {
    return ProfileApi.updatePassword(passwordChange).then(changed => {
      return Promise.resolve(changed)
    }).catch(reason => dispatch('globalStore/addFlashMessage', createFlashMessage({
      type: 'error',
      message: reason
    })))
  },
  reset ({ commit, dispatch }) {
    dispatch('deviceStore/reset', null, { root: true })
    commit(RESET_STATE)
  },
}

const mutations = {
  [SET_AUTH_USER] (state, user) {
    localStorage.setItem(LOCAL_STORAGE_AUTH_USER, JSON.stringify(user))
    state.user = user
  },
  [ADD_FLASH_MESSAGE] (state, message) {
    state.flash = message
  },
  [SET_TOKEN] (state, token) {
    localStorage.setItem(LOCAL_STORAGE_JWT_TOKEN, JSON.stringify(token))
    state.token = token
  },
  [RESET_FLASH] (state) {
    state.flash = {}
  },
  [RESET_STATE] (state) {
    localStorage.clear()
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
