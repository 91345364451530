<template>
  <v-app id="open-agf-app" v-if="isAuthenticated">
    <v-app-bar app color="white" light>
      <router-link class="toolbar-title" to="/">
        <v-toolbar-title class="headline text-uppercase mr-3">
          <span class="hidden-sm-and-up font-weight-bold">SF</span>
          <span class="hidden-xs-only font-weight-bold">Smart</span>
          <span class="hidden-xs-only font-weight-light">Fruit</span>
        </v-toolbar-title>
      </router-link>

      <v-toolbar-items>
        <v-btn text v-for="item in items" :to="item.link" :key="item.title">
          <v-icon class="pr-2" :color="item.iconColor">{{ item.icon }}</v-icon>
          <span class="hidden-sm-and-down">{{ item.title }}</span>
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>

      <v-toolbar-items>
        <v-btn text to="/profile">
          <v-icon class="mr-2">person</v-icon>
          <span class="hidden-sm-and-down"> {{ authenticatedUser.name }}</span>
        </v-btn>
        <v-btn @click="logout" text>
          <v-icon color="red">logout</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <alert-bar/>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import AlertBar from '@/components/AlertBar'

export default {
  name: 'Dashboard',
  components: {
    AlertBar,
  },
  data () {
    return {
      drawer: true,
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', iconColor: 'green', link: '/' },
        { title: 'Apparaten', icon: 'devices', iconColor: 'blue', link: '/devices' },
      ],
    }
  },
  computed: {
    isAuthenticated () {
      return this.$store.getters['globalStore/isAuthenticated']
    },
    authenticatedUser () {
      return this.$store.getters['globalStore/user']
    },
  },
  methods: {
    logout () {
      this.$store.dispatch('globalStore/reset')
        .then(() => {
          this.$store.dispatch('globalStore/addFlashMessage', {
            type: 'warning',
            message: 'Je bent uitgelogd, kom je gauw terug?',
          })
          this.$router.push({ name: 'login' })
        })
    },
  },
  mounted () {
    this.$store.dispatch('deviceStore/load')
  },

}
</script>
<style lang="scss">
.toolbar-title {
  color: black;
  text-decoration: none;
}
</style>
