import { SET_DEVICE, RESET_STATE } from '@/stores/mutations'
import { createFlashMessage } from '@/stores/utils'
import DevicesApi from '@/api/DevicesApi'
import Vue from 'vue'

const initialState = () => {
  return {
    devices: [],
  }
}

const state = initialState

const getters = {
  devices (state) {
    return state.devices
  },
}

const actions = {
  resetToActiveState ({ commit, dispatch }, device) {
    return DevicesApi.markActive(device.id)
      .then(device => {
        commit(SET_DEVICE, device)
        dispatch('globalStore/addFlashMessage', {
          type: 'success',
          message: `Apparaat ${device.name} is bijgewerkt.`,
        }, { root: true })
      })
      .catch(reason =>
        dispatch('globalStore/addFlashMessage',
          createFlashMessage({ type: 'error', message: reason }), { root: true })
      )
  },
  update ({ commit, dispatch }, device) {
    return DevicesApi.update(device)
      .then(device => {
        commit(SET_DEVICE, device)
        dispatch('globalStore/addFlashMessage', {
          type: 'success',
          message: `Apparaat ${device.name} is bijgewerkt.`,
        }, { root: true })
      })
      .catch(reason =>
        dispatch('globalStore/addFlashMessage',
          createFlashMessage({ type: 'error', message: reason }), { root: true })
      )
  },
  load ({ commit, dispatch }) {
    return DevicesApi.all()
      .then(devices => {
        if (!Array.isArray(devices)) return Promise.reject('Kan geen apparaten ophalen.')
        devices.forEach(device => commit(SET_DEVICE, device))
        return Promise.resolve(devices.length)
      })
      .catch(reason => dispatch('globalStore/addFlashMessage', createFlashMessage({
        type: 'error',
        message: reason
      }), { root: true }))
  },
  reset ({ commit }) {
    return new Promise((resolve) => {
      commit(RESET_STATE)
      resolve(true)
    })
  },
}

const mutations = {
  [SET_DEVICE] (state, device) {
    let idx = state.devices.findIndex(d => d.id === device.id)
    if (idx !== -1) {
      Vue.set(state.devices, idx, device)
    } else {
      state.devices.push(device)
    }
  },
  [RESET_STATE] (state) {
    localStorage.clear()
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
    })
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions,
}
