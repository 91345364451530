import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/stores/index'
import vuetify from './plugins/vuetify'
import luxon from './plugins/luxon.js'

Vue.use(luxon)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')


